import { ReactNode } from "react"
import styles from "./ui.module.css"

type Props = {
  children: ReactNode;
  type?: "confirm" | "default";
  onClick?: () => void;
  disabled?: boolean;
}

const Button = (props: Props) => {
  let typeClass: string

  switch (props.type || "default") {
    case "confirm":
      typeClass = styles.btnConfirm
      break
    case "default":
      typeClass = styles.btnDefault
      break
  }

  return (
    <button disabled={props.disabled} className={`${styles.btn} ${typeClass}`} onClick={props.onClick}>
      {props.children}
    </button>
  )
}

export default Button