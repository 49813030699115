import { FormEvent, useCallback, useState } from "react"
import styles from "./login-page.module.css"
import { SetState } from "../../../utils"
import { Login, useAppContext } from "../../../context/context"
import withRedirectLogged from "../../../service/with-redirect-logged"
import Modal from "../../ui/modal"
import Button from "../../ui/button"

type State = {
  authErrorModalOpen: boolean;
  username: string;
  password: string;
}

const LoginPage = () => {
  const { login } = useAppContext()
  const [ state, setState ] = useState<State>({
    authErrorModalOpen: false,
    username: "",
    password: ""
  })
  const { username, password } = state

  const onFormSubmit = useCallback((e: FormEvent) => baseOnFormSubmit({ e, username, password, login, setState }), [ username, password, login, setState ])

  const onAlertClose = () => setState(prev => ({ ...prev, authErrorModalOpen: false }))

  return (
    <div className={styles.wrapper}>
      <Modal open={state.authErrorModalOpen} onClose={onAlertClose}>
        Usuário ou senha incorretos
        <div style={{ height: "20px" }}></div>
        <Button type="confirm" onClick={onAlertClose}>
          Ok
        </Button>
      </Modal>
      <form className={styles.loginForm} onSubmit={onFormSubmit}>
        <div className={styles.inputGrp}>
          <label htmlFor="username">Usuário</label>
          <input id="username" type="text" value={username} onChange={e => setState(prev => ({ ...prev, username: e.target.value }))}/>
        </div>
        <div style={{ height: "20px" }}></div>
        <div className={styles.inputGrp}>
          <label htmlFor="password">Senha</label>
          <input id="password" type="password" value={password} onChange={e => setState(prev => ({ ...prev, password: e.target.value }))}/>
        </div>
        <div style={{ height: "20px" }}></div>
        <div className={styles.buttonWrapper}>
          <button className={styles.loginBtn}>
            Acessar
          </button>
        </div>
      </form>
    </div>
  )
}

const baseOnFormSubmit = async (params: { 
  e: FormEvent;
  login: Login;
  username: string;
  password: string;
  setState: SetState<State>;
}) => {
  const { e, login, username, password, setState } = params

  e.preventDefault()

  try {
    await login({ username, password })
  } catch (e) {
    setState(prev => ({ ...prev, authErrorModalOpen: true }))
  }
}

export default withRedirectLogged(LoginPage)