export type SetState<T> = React.Dispatch<React.SetStateAction<T>>

export const baseUrl = process.env.REACT_APP_API_URL

export type ApiCouponItem = {
  access_key: string;
  status: string;
  issue_dt: string;
  cnpj: string;
  horus_company_brand: string;
  razao_social: string;
  address: string;
  uf: string;
  city: string;
  coupon_value: number;
  payment: string;
  horus_prod_description: string;
  item_desc: string;
  ncm: string;
  ean_com: string;
  ean_trib: string;
  cod_prod: string;
  item_value: number;
  item_discount: number;
  qty: number;
  horus_prod_id: number;
  horus_prod_ean: string;
  horus_qtd: number;
  horus_unidade: string;
  horus_qtd_pack: number;
  horus_prod_manufacturer: string;
  horus_prod_brand: string;
  horus_prod_category: string;
  horus_prod_segment: string;
  horus_identifier: string;
  horus_type: 'NORMAL' | 'FRACIONADO';
}

export function formatValueToBRL(value: number | string | null | undefined): string {
  if (!value) {
    return ""
  }
  const numericValue = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
  const formattedValue = numericValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  return formattedValue;
}
