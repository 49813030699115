import { useEffect } from "react"
import { useAppContext } from "../context/context"

const withAuthCheck = <T extends object>(Component: React.ComponentType<T>) => {
  return (props: T) => {
    const { logged, logout } = useAppContext()

    useEffect(() => {
      if (logged === false) {
        logout()
      }
    }, [ logged, logout ])

    if (logged === undefined) {
      return (
        <>
          Carregando...
        </>
      )
    }

    if (logged) {
      return <Component {...props} />
    }

    return null
  }
}

export default withAuthCheck