import { useEffect, useRef, useState } from "react";
import useHttp, { Http, Request } from "../../../../service/use-http";
import { ApiCouponItem, formatValueToBRL, SetState } from "../../../../utils";
import Button from "../../../ui/button"
import Modal from "../../../ui/modal";
import styles from "./product-side-bar.module.css"

type State = {
  term: string;
  searching: boolean;
  products: ProductOption[];
  productSelected: ProductOption | null;
  alert?: string;
  sucessMessage?: string;
  saving: boolean;
}

type ProductOption = {
  horus_prod_id: number;
  horus_prod_ean: string;
  horus_prod_description: string;
  horus_prod_manufacturer: string;
  horus_prod_brand: string;
}

type Props = {
  item: ApiCouponItem | null;
  onClose?: () => void;
  onSaved?: () => void;
}

const ProductSideBar = (props: Props) => {
  const { item, onClose, onSaved } = props
  const [ state, setState ] = useState<State>({
    products: [],
    term: "",
    searching: false,
    productSelected: null,
    saving: false
  })
  const { products, term, searching, productSelected, alert, saving, sucessMessage } = state
  const http = useHttp()

  const lastSetTimeoutHandle = useRef<any>();

  const onCloseModal = () => setState(prev => ({ ...prev, alert: undefined }))

  const onCloseSuccessModal = () => {
    setState(prev => ({ ...prev, sucessMessage: undefined }))
    onSaved?.()
  }

  const onSave = () => baseOnSave({ http, setState, productSelected, item })

  const onUnlink = () => baseOnUnlink({ http, setState, productSelected, item })

  useEffect(() => { searchProductsEffect({ setState, http, term, lastSetTimeoutHandle }) }, [ setState, http, term, lastSetTimeoutHandle ])

  useEffect(() => { setProductSelectedEffect({ item, setState }) }, [ item, setState ])

  if (!item) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <Modal open={Boolean(alert)} onClose={onCloseModal}>
        {alert}
        <div style={{ height: "20px" }}></div>
        <Button onClick={onCloseModal} type="confirm">
          Ok
        </Button>
      </Modal>
      <Modal open={Boolean(sucessMessage)} onClose={onCloseSuccessModal}>
        {sucessMessage}
        <div style={{ height: "20px" }}></div>
        <Button onClick={onCloseSuccessModal} type="confirm">
          Ok
        </Button>
      </Modal>
      <div className={styles.sideBar}>
        <div className={styles.closeButton} onClick={onClose}>
        </div>
        <div className={styles.header}>
          Identificar
          <div className={styles.productName}>
            {item.item_desc}
          </div>
        </div>
        <div className={styles.title}>
          Dados do Item
        </div>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>Valor do Item</td>
              <td>{formatValueToBRL(item.item_value) || '--'}</td>
            </tr>
            <tr>
              <td>Ean do Item</td>
              <td>{item.ean_com || '--'}</td>
            </tr>
            <tr>
              <td>Código de Produto</td>
              <td>{item.cod_prod || '--'}</td>
            </tr>
          </tbody>
        </table>
        <div className={styles.title}>
          Dados do Produto
        </div>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>Desc</td>
              <td>{productSelected?.horus_prod_description || '--'}</td>
            </tr>
            <tr>
              <td>Ean</td>
              <td>{productSelected?.horus_prod_ean || '--'}</td>
            </tr>
            <tr>
              <td>Marca</td>
              <td>{productSelected?.horus_prod_brand || '--'}</td>
            </tr>
            <tr>
              <td>Fabricante</td>
              <td>{productSelected?.horus_prod_manufacturer || '--'}</td>
            </tr>
          </tbody>
        </table>
        <div className={styles.title}>
          Selecionar Produto
        </div>
        <input
          className={styles.search}
          type="text"
          placeholder="Pesquisar por Ean ou Descrição"
          value={term}
          onChange={e => setState(prev => ({ ...prev, term: e.target.value }))} />
        <div className={styles.selectBox}>
          {
            products.length !== 0 ? null : (
              searching ? (
                <div className={styles.selectItem}>
                  <div className={styles.selectItemTitleSingle}>
                    Pesquisando...
                  </div>
                </div>
              ) : (
                <div className={styles.selectItem}>
                  <div className={styles.selectItemTitleSingle}>
                    Sem Resultados
                  </div>
                </div>
              )
            )
          }
          {
            products.length === 0 ? null : (
              products.map(product => (
                <div className={styles.selectItem} key={product.horus_prod_id}>
                  <div
                    className={`${styles.selectItemLeft} ${product.horus_prod_id === productSelected?.horus_prod_id ? styles.selectItemActive : null}`} 
                    onClick={() => setState(prev => ({
                    ...prev, productSelected: product.horus_prod_id === productSelected?.horus_prod_id ? null : product
                    }))}>
                    <div className={styles.selectItemRadio}>
                    </div>
                  </div>
                  <div className={styles.selectItemRight}>
                    <div className={styles.selectItemTitle}>
                      {product.horus_prod_description}
                    </div>
                    <div className={styles.selectItemAttribute}>
                      <span className={styles.selectItemAttributeName}>Ean: </span>
                      {product.horus_prod_ean}
                    </div>
                    <div className={styles.selectItemAttribute}>
                      <span className={styles.selectItemAttributeName}>Fabricante: </span>
                      {product.horus_prod_manufacturer}
                    </div>
                    <div className={styles.selectItemAttribute}>
                      <span className={styles.selectItemAttributeName}>Marca: </span>
                      {product.horus_prod_brand}
                    </div>
                  </div>
                </div>
              ))
            )
          }
        </div>
        <div className={styles.title}>
          Ações
        </div>
        <div className={styles.buttons}>
          <Button
            type="confirm"
            disabled={saving || !productSelected}
            onClick={onSave}>
            Salvar
          </Button>
          {
            item.horus_identifier !== "MANUAL" || (
              <Button
                disabled={saving}
                onClick={onUnlink}>
                Desvincular
              </Button>
            )
          }
          <Button disabled={saving} onClick={onClose}>
            Fechar
          </Button>
        </div>
      </div>
    </div>
  )
}

const baseOnUnlink = async (params: {
  http: Http;
  item: ApiCouponItem | null;
  productSelected: ProductOption | null;
  setState: SetState<State>;
}) => {
  const { http, productSelected, setState, item } = params

  if (!item || !productSelected) {
    return
  }

  setState(prev => ({
    ...prev, saving: true
  }))

  try {
    const request: Request = { 
      url: `/api/product/assoc?cnpj=${item.cnpj}&cod_prod=${item.cod_prod}`,
      method: "DELETE"
    }
    const response = await http.send(request)
    if (response.error) {
      throw new Error()
    }
    setState(prev => ({
      ...prev, sucessMessage: "Produto desvinculado com sucesso"
    }))
  } catch (e) {
    setState(prev => ({
      ...prev, alert: "Erro ao Salvar"
    }))
  } finally {
    setState(prev => ({
      ...prev, saving: false
    }))
  }

}

const baseOnSave = async (params: {
  http: Http;
  item: ApiCouponItem | null;
  productSelected: ProductOption | null;
  setState: SetState<State>;
}) => {
  const { http, productSelected, setState, item } = params

  if (!item || !productSelected) {
    return
  }

  setState(prev => ({
    ...prev, saving: true
  }))

  try {
    const request: Request = { 
      url: "/api/product/assoc",
      body: {
        cnpj: item.cnpj,
        cod_prod: item.cod_prod,
        prod_id: productSelected.horus_prod_id
      },
      method: "POST"
    }
    const response = await http.send(request)
    if (response.error) {
      throw new Error()
    }
    setState(prev => ({
      ...prev, sucessMessage: "Produto vinculado com sucesso"
    }))
  } catch (e) {
    setState(prev => ({
      ...prev, alert: "Erro ao Salvar"
    }))
  } finally {
    setState(prev => ({
      ...prev, saving: false
    }))
  }

}

const searchProductsEffect = (params: {
  lastSetTimeoutHandle: React.MutableRefObject<any>;
  http: Http;
  term: string;
  setState: SetState<State>;
}) => {
  const { lastSetTimeoutHandle, http, term, setState } = params

  if (lastSetTimeoutHandle.current) {
    clearTimeout(lastSetTimeoutHandle.current)
  }

  lastSetTimeoutHandle.current = setTimeout(() => {
    const fn = async () => {
      if (!term) {
        setState(prev => ({
          ...prev, products: [], searching: false
        }))
        return
      }
      setState(prev => ({
        ...prev, searching: true
      }))
      const request = {
        url: "/api/product/options?q=" + term
      }
      try {
        const response = await http.send<{ result: State["products"] }>(request)
        if (!response.error) {
          setState(prev => ({
            ...prev, products: response.data.result
          }))
        }
      } catch (e) {
        console.log(e)
      } finally {
        setState(prev => ({
          ...prev, searching: false
        }))
      }
    }

    fn()
  }, 500)

}

const setProductSelectedEffect = (params: { item: ApiCouponItem | null, setState: SetState<State> }) => {
  const { item, setState } = params

  if (item?.horus_prod_id) {
    setState(prev => ({
      ...prev, productSelected: {
        horus_prod_id: item.horus_prod_id,
        horus_prod_ean: item.horus_prod_ean,
        horus_prod_description: item.horus_prod_description,
        horus_prod_manufacturer: item.horus_prod_manufacturer,
        horus_prod_brand: item.horus_prod_brand
      }
    }))
  } else {
    setState(prev => ({
      ...prev, productSelected: null
    }))
  }
}

export default ProductSideBar