import Button from "../../ui/button"
import ProductSideBar from "./product-side-bar/product-side-bar"
import styles from "./coupon-page.module.css"
import useHttp, { Http } from "../../../service/use-http"
import { ApiCouponItem, formatValueToBRL, SetState } from "../../../utils"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Modal from "../../ui/modal"

type State = {
  result?: ApiCouponItem[];
  alertLoadError?: string;
  alertReleaseError?: string;
  alertReleaseSuccess?: string;
  releaseCouponConfirmOpen: boolean;
  itemOpen: ApiCouponItem | null;
  saving: boolean;
}

const CouponPage = () => {
  const [ state, setState ] = useState<State>({ 
    itemOpen: null,
    saving: false,
    releaseCouponConfirmOpen: false
  })

  const { releaseCouponConfirmOpen, alertLoadError, alertReleaseError, alertReleaseSuccess, result, itemOpen, saving } = state

  const navigate = useNavigate()

  const http = useHttp()

  const { accesskey } = useParams()

  const loadCoupon = useCallback(() => baseLoadCoupon({ accesskey, http, setState }), [ accesskey, http, setState ])

  const onOpenItem = (itemOpen: ApiCouponItem) => setState(prev => ({ ...prev, itemOpen }))

  const onSidePanelClose = () => setState(prev => ({ ...prev, itemOpen: null }))

  const onReleaseCoupon = () => baseReleaseCoupon({ http, setState, accesskey })

  const onCloseModal = () => setState(prev => ({ ...prev, releaseCouponConfirmOpen: false, alertReleaseError: undefined, alertReleaseSuccess: undefined }))

  const onGoBackToQueue = () => navigate("/fila")

  const onSidePanelSaved = () => {
    loadCoupon()
    setState(prev => ({ ...prev, itemOpen: null }))
  }

  useEffect(() => { loadCoupon() }, [ loadCoupon ])

  return (
    <div className={styles.wrapper}>
      <ProductSideBar
        item={itemOpen}
        onClose={onSidePanelClose}
        onSaved={onSidePanelSaved}
      />
      <Modal
        onClose={onCloseModal}
        open={releaseCouponConfirmOpen}>
          Confirma liberar essa nota?
          <div style={{ height: "20px" }}></div>
          <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
            <Button onClick={onReleaseCoupon} type="confirm">
              Sim
            </Button>
            <Button onClick={onCloseModal}>
              Não
            </Button>
          </div>
      </Modal>
      <Modal
        onClose={onCloseModal}
        open={Boolean(alertReleaseError)}>
        {alertReleaseError}
        <div style={{ height: "20px" }}></div>
        <Button onClick={onCloseModal}>
          Ok
        </Button>
      </Modal>
      <Modal
        onClose={onCloseModal}
        open={Boolean(alertReleaseSuccess)}>
        {alertReleaseSuccess}
        <div style={{ height: "20px" }}></div>
        <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <Button onClick={onCloseModal}>
            Ok
          </Button>
          <Button onClick={onGoBackToQueue}>
            Voltar para Fila
          </Button>
        </div>
      </Modal>
      <div className={styles.header}>
        Identificação Manual de Produtos
      </div>
      <div className={styles.content}>
        <div className={styles.subtitle}>
          Dados da Nota
        </div>
        {
          alertLoadError ? (
            <div className={styles.alertBox}>
              {alertLoadError}
              <div style={{ height: "10px" }}></div>
              <Button onClick={loadCoupon}>
                Tentar Novamente
              </Button>
            </div>
          ) : (
            result === undefined ? (
              <div className={styles.alertBox}>
                Carregando...
              </div>
            ) : (
              result.length === 0 ? (
                <div className={styles.alertBox}>
                  Nota não encontrada
                </div>
              ) : (
                <>
                  <table className={`${styles.table} ${styles.tableNota}`}>
                    <tbody>
                      <tr>
                        <td>Chave de Acesso</td>
                        <td>{result[0].access_key}</td>
                      </tr>
                      <tr>
                        <td>Razão Social</td>
                        <td>{result[0].razao_social}</td>
                      </tr>
                      <tr>
                        <td>Endereço</td>
                        <td>{result[0].address}</td>
                      </tr>
                      <tr>
                        <td>Valor Total da Nota Fiscal</td>
                        <td>{formatValueToBRL(result[0].coupon_value)}</td>
                      </tr>
                      <tr>
                        <td>Data de Envio</td>
                        <td>{result[0].issue_dt}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={styles.subtitle}>
                    Items
                  </div>
                  <table className={`${styles.table} ${styles.tableItems}`}>
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Ean Item</th>
                        <th>Cod Item</th>
                        <th>Qtd</th>
                        <th>Valor Total</th>
                        <th>Ean Horus</th>
                        <th>Descrição Produto Horus</th>
                        <th>Fabricante Horus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        result.map(item => (
                          <tr>
                            <td>{item.item_desc}</td>
                            <td>{item.ean_com || item.ean_trib || '--'}</td>
                            <td>{item.cod_prod}</td>
                            <td>{item.qty}</td>
                            <td>{formatValueToBRL(item.item_value)}</td>
                            <td>{item.horus_prod_ean || '--'}</td>
                            <td>{item.horus_prod_description || '--'}</td>
                            <td>{item.horus_prod_manufacturer || '--'}</td>
                            <td>
                              {
                                <Button onClick={() => onOpenItem(item)}>
                                  Identificar
                                </Button>
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                  <div className={styles.subtitle}>
                    Ações
                  </div>
                  <div className={styles.buttons}>
                    <Button 
                      disabled={saving}
                      onClick={() => setState(prev => ({ ...prev, releaseCouponConfirmOpen: true }))}
                      type="confirm">
                        Liberar Nota
                    </Button>
                    <Button
                      disabled={saving}
                      onClick={onGoBackToQueue}>
                        Voltar
                    </Button>
                  </div>
                </>
              )
            )
          )
        }
      </div>
    </div>
  )
}

const baseReleaseCoupon = async (params: {
  http: Http;
  setState: SetState<State>;
  accesskey: string | undefined;
}) => {
  const { http, setState, accesskey } = params

  setState(prev => ({
    ...prev, releaseCouponConfirmOpen: false
  }))

  if (!accesskey) return;

  try {
    setState(prev => ({
      ...prev, saving: true
    }))
    const request = { url: `/api/coupon/${accesskey}/release`, method: "POST" }
    const response = await http.send<{ result: ApiCouponItem[] }>(request)
    if (response.error) {
      throw new Error()
    }
    setState(prev => ({
      ...prev, alertReleaseSuccess: "Nota liberada com sucesso!"
    }))
  } catch (e) {
    setState(prev => ({
      ...prev, alertReleaseError: "Erro ao Liberar Nota"
    }))
  } finally {
    setState(prev => ({
      ...prev, saving: false
    }))
  }
}

const baseLoadCoupon = async (params: {
  http: Http;
  setState: SetState<State>;
  accesskey: string | undefined;
}) => {
  const { http, setState, accesskey } = params

  if (!accesskey) {
    setState(prev => ({ 
      ...prev, result: []
    }))
  }

  setState(prev => ({ 
    ...prev, alertLoadError: undefined, result: undefined
  }))

  try {
    const request = { url: `/api/coupon/${accesskey}` }
    const response = await http.send<{ result: ApiCouponItem[] }>(request)
    if (response.error) {
      throw new Error()
    }
    setState(prev => ({ 
      ...prev, result: response.data.result
    }))
  } catch (e) {
    setState(prev => ({ 
      ...prev, alertLoadError: "Erro ao recuperar nota"
    }))
  }
}

export default CouponPage