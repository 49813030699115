import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../../../context/context"
import useHttp, { Http } from "../../../service/use-http"
import withAuthCheck from "../../../service/with-auth-check"
import { SetState } from "../../../utils"
import Button from "../../ui/button"
import Modal from "../../ui/modal"
import styles from "./queue-page.module.css"

type QueueItem = {
  access_key: string;
}

type State = {
  queue?: QueueItem[];
  alert?: string;
}

const QueuePage = () => {
  const { logout } = useAppContext()
  const navigate = useNavigate()
  const http = useHttp()

  const [ state, setState ] = useState<State>({})
  const { queue, alert } = state

  const loadQueue = useCallback(() => baseLoadQueue({ http, setState }), [ http, setState ])

  useEffect(() => { loadQueue() }, [ loadQueue ])

  const dismissModal = () => setState(prev => ({ ...prev, alert: undefined }))

  return (
    <div className={styles.wrapper}>
      <Modal open={Boolean(alert)} onClose={dismissModal}>
        {alert}
        <div style={{ height: "20px" }}></div>
        <Button onClick={dismissModal} type="confirm">
          Ok
        </Button>
      </Modal>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          Identificação Manual de Produtos
        </div>
        <button className={styles.logout} onClick={logout}>
          Sair
        </button>
      </div>
      <div className={styles.content}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Chave de Acesso</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {
              queue === undefined ? (
                <tr>
                  <td colSpan={2}>
                    Carregando....
                  </td>
                </tr>
              ) : (
                !queue.length ? (
                  <tr>
                    <td colSpan={2}>
                      A fila está vazia
                    </td>
                  </tr>
                ) : (
                  queue.map(item => (
                    <tr key={item.access_key}>
                      <td>
                        {item.access_key}
                      </td>
                      <td>
                        <Button onClick={() => navigate(`/nota/${item.access_key}`)}>
                          Identificar
                        </Button>
                      </td>
                    </tr>
                  ))
                )
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

const baseLoadQueue = async (params: {
  http: Http;
  setState: SetState<State>;
}) => {
  const { http, setState } = params
  
  try {
    const request = { url: "/api/identification-queue" }
    const response = await http.send<{ result: QueueItem[] }>(request)
    if (response.error) {
      throw new Error()
    }
    setState(prev => ({ 
      ...prev, queue: response.data.result
    }))
  } catch (e) {
    setState(prev => ({ 
      ...prev, alert: "Erro ao recuperar fila"
    }))
  }
}


export default withAuthCheck(QueuePage)