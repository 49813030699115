import { useEffect } from "react"
import { useAppContext } from "../context/context"

const withRedirectLogged = <T extends object>(Component: React.ComponentType<T>) => {
  return (props: T) => {
    const { logged, redirectToHome } = useAppContext()

    useEffect(() => {
      if (logged === true) {
        redirectToHome()
      }
    }, [ logged, redirectToHome ])

    console.log("logged", logged)

    if (logged === undefined) {
      return (
        <>
          Carregando...
        </>
      )
    }

    if (!logged) {
      return <Component {...props} />
    }

    return null
  }
}

export default withRedirectLogged