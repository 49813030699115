import { useCallback, useMemo } from "react"
import axios, { AxiosError } from "axios"
import { useAppContext } from "../context/context";
import { baseUrl } from "../utils";

export type Request = {
  url: string;
  method?: string;
  auth?: boolean;
  body?: any;
}

export type Response<T> = {
  error: false;
  data: T;
  status: number;
} | {
  error: true;
  data: { message: string };
  status: number;
}

export type Http = ReturnType<typeof useHttp>

const useHttp = () => {
  const { logout } = useAppContext()

  const send = useCallback(async <T>(request: Request): Promise<Response<T>> => {
    try {
      const headers: Record<string, string> = {}
      if (request.auth || request.auth === undefined) {
        const authToken = localStorage.getItem("token")
        if (authToken) {
          headers.Authorization = `Basic ${authToken}`
        }
      }
      const response = await axios.request({
        url: baseUrl + request.url,
        method: request.method || "GET",
        data: request.body,
        headers
      })
      return {
        error: false,
        status: response.status,
        data: response.data
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        if (e.response.status === 401) {
          logout()
        }
        return {
          error: true,
          status: e.response.status,
          data: e.response.data
        }
      }
      throw e
    }
  }, [ logout ])

  const http = useMemo(() => ({ send }), [ send ])

  return http
}

export default useHttp