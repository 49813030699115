import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LoginPage from './components/page/login-page/login-page'
import CouponPage from './components/page/coupon-page/coupon-page'
import { AppContextProvider } from './context/context'
import QueuePage from './components/page/queue-page/queue-page'

const App = () => {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/fila" element={<QueuePage />} />
          <Route path="/nota/:accesskey" element={<CouponPage />} />
        </Routes>
      </AppContextProvider>
    </BrowserRouter>
  )
}

export default App
