import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import axios, { AxiosError } from "axios"
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils";

export type ContextProps = {
  logged?: boolean;
  logout: () => void;
  login: (params: { username: string, password: string }) => Promise<void>;
  redirectToHome: () => void;
}

export type Login = ContextProps["login"]

export type Logout = ContextProps["logout"]

const Context = createContext<ContextProps>({} as any)

export const useAppContext = () => {
  return useContext(Context)
}

export const AppContextProvider = (props: { children: ReactNode }) => {
  const navigate = useNavigate()

  const [ { logged }, setState ] = useState<{ logged?: boolean }>({ logged: undefined })

  const logout = useCallback(() => {
    setState(prev => ({ ...prev, logged: false }))
    localStorage.removeItem("token")
    navigate("/")
  }, [ navigate ])

  const redirectToHome = useCallback(() => {
    navigate("/fila")
  }, [ navigate ])

  const login = useCallback(async (params: { username: string, password: string }) => {
    const { username, password } = params
    const token = btoa(username + ":" + password)
    try {
      await axios.get(baseUrl + "/api/check-auth", {
        headers: {
          Authorization: `Basic ${token}`
        }
      })
      localStorage.setItem("token", token)
      setState(prev => ({ ...prev, logged: true }))
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response && e.response.status === 401) {
          throw new Error("Usuário ou Senha incorretos")
        }
      }
      throw new Error("Um erro ocorreu ao se autenticar")
    }
  }, [ setState ])

  const restoreAuthState = useCallback(async () => {
    const token = localStorage.getItem("token")
    if (!token) {
      setState(prev => ({ ...prev, logged: false }))
    }
    try {
      await axios.get(baseUrl + "/api/check-auth", {
        headers: {
          Authorization: `Basic ${token}`
        }
      })
      setState(prev => ({ ...prev, logged: true }))
    } catch (e) {
      localStorage.removeItem("token")
      setState(prev => ({ ...prev, logged: false }))
    }
  }, [ setState ])

  useEffect(() => { restoreAuthState() }, [ restoreAuthState ])

  return (
    <Context.Provider value={{ redirectToHome, login, logout, logged }}>
      {props.children}
    </Context.Provider>
  )
}

