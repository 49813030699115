import React, { ReactNode } from "react"
import styles from "./ui.module.css"

type Props = {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
}

const Modal = (props: Props) => {
  if (!props.open) {
    return null
  }

  const handleOnWrapperClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      props.onClose?.()
    }
  }

  return (
    <div className={styles.modalWrapper} onClick={handleOnWrapperClick}>
      <div className={styles.modal}>
        {props.children}
      </div>
    </div>
  )
}

export default Modal